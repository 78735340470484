import React from "react"
// Components
import { SectionTitle } from "../../components/styled"
import Contact from "../../components/Contact"
import { Seo } from "../../components/seo"
const TermsOfUseEn = () => {
  return (
    <section className="container termsofuse">
      <div className="row row-en">
        <div className="termsofuse__about-security">
          <SectionTitle>
            Terms and Conditions of Use of Quick Global México, S.A. de C.V.
            Services for Clients
          </SectionTitle>
          <hr className="u-line-bottom" />
          <p>
            Before requesting the recruitment and personnel selection services
            offered by Quick Global México, S.A. de C.V. (hereinafter referred
            to as “Our Company”), we kindly ask our clients to carefully read,
            understand, and agree to these Terms and Conditions of Quick Global
            México, S.A. de C.V. Services for Clients (hereinafter referred to
            as “The Terms”).
          </p>
          <h2>Clause 1: Personnel Recruitment Services</h2>
          <p>
            The recruitment and personnel selection services (hereinafter
            referred to as “The Services”) refer to the following services
            provided by “Our Company” and related services:
          </p>

          <ol>
            <li>
              <b>Support Service for Recruitment and Personnel Selection</b>
            </li>
            <p style={{ marginTop: 0 }}>
              This is the general term for “The Services” provided by “Our
              Company” which includes: matching the desired employment
              conditions of the user (job seeker) with the requirements of
              companies requesting personnel, interviews and consultations about
              changing jobs with our employment advisors, introducing job
              offers, supporting job change activities, representing the user in
              negotiations with companies requesting personnel (from the moment
              of applying for the job until employment begins), and other
              services deemed beneficial for the user.
            </p>
            <li>
              <b>Newsletter Service</b>
            </li>
            <p style={{ marginTop: 0 }}>
              This is a service that sends an informative newsletter containing
              useful information for job change.
            </p>
          </ol>
          <h2>Clause 2: User</h2>
          <ol>
            <li>
              The user is the person who has requested the use of “The Services”
              by consenting to “The Terms” and the handling of personal
              information and has been accepted by “Our Company” for the use of
              “The Services”.
            </li>
            <li>
              Once the user has requested the use of “The Services” it will be
              considered that the user has accepted all the content of “The
              Terms” and the handling of personal information.
            </li>
            <li>
              If there is missing information in the personal data provided by
              the user, there may be cases in which “Our Company” cannot offer
              “The Services”.
            </li>
          </ol>
          <h2>Clause 3: Service Request and Applicant Investigation</h2>
          <ol>
            <li>
              In some cases, “Our Company” will conduct background checks on the
              applicant for “The Services” based on the needs. In this case, the
              application process for “The Services” will only be completed for
              cases approved by “Our Company”.
            </li>
            <li>
              If “Our Company” deems the applicant unsuitable for providing “The
              Services” we may reject their use of “The Services” at any time.
              “Our Company” is not obligated to respond to any questions or
              inquiries regarding the resolution or to address the matter in any
              way.
            </li>
            <li>
              “Our Company” is not responsible for any damages related to the
              above points.
            </li>
          </ol>
          <h2>Clause 4: Handling of Personal Information</h2>
          <p>
            “Our Company” will collect, use, deliver, and manage the personal
            information of the user in accordance with the Personal Information
            Management regulations stated later.
          </p>
          <h2>
            Clause 5: Matching with the Requirements of the Hiring Company
          </h2>
          <p>
            “Our Company” will match the employment conditions desired by the
            user with the job profiles requested by the hiring company; however,
            we cannot inform the user of the evaluation criteria or the reasons
            or grounds for any decisions made.
          </p>
          <h2>Clause 6: Prohibitions for the User</h2>
          <p>
            The user shall not engage in the following acts when applying for or
            using this service:
          </p>
          <ol>
            <li>Providing or declaring false information.</li>
            <li>
              Defaming, insulting, or slandering the company, its stakeholders,
              other users, or third parties related to the company or its
              business.
            </li>
            <li>
              Violating the rights, including property, honor, privacy,
              copyright, trademark rights, and other intellectual property
              rights, of the company, its stakeholders, other users, or third
              parties.
            </li>
            <li>
              Interfering with the operation of the service or damaging the
              company's social credibility or reputation.
            </li>
            <li>
              Disclosing or leaking information obtained through the service to
              third parties.
            </li>
            <li>
              Engaging in actions that violate public order, morals, or laws, or
              actions that may lead to such violations.
            </li>
            <li>
              Engaging in acts related to illegal or antisocial organizations,
              providing benefits to such organizations, using such
              organizations, or engaging in acts that may cause harm due to such
              organizations.
            </li>
          </ol>
          <h2>Clause 7: Suspension or Termination of Services</h2>
          <ol>
            <li>
              If “Our Company” determines that the user has violated “The Terms”
              or that a relationship of trust cannot be maintained between the
              user and “Our Company” we may suspend or terminate the provision
              of “The Services” without prior notice to the user. “Our Company”
              assumes no responsibility for any damages arising from the
              aforementioned. It is important to note that this does not prevent
              “Our Company” from pursuing civil liability against the user.
            </li>
            <li>
              Regarding the previous paragraph, “Our Company” is not obligated
              to respond to any questions related to our decision nor to address
              the matter in any way.
            </li>
          </ol>
          <h2>Clause 8 (Changes and Suspension of the Service)</h2>
          <p>
            The company may change or temporarily suspend all or part of the
            service without notice or consent from the user in the following
            cases:
          </p>

          <ol>
            <li>
              Maintenance, inspection, update of the system, or other emergency
              necessities required to provide the service.
            </li>
            <li>
              When it becomes difficult to provide the service due to system
              failures, virus damage, or natural disasters such as earthquakes,
              fires, or power outages, which cannot be prevented by ordinary
              security measures.
            </li>
            <li>
              When the company determines that it is difficult to provide the
              service due to unforeseen circumstances.
            </li>
          </ol>
          <h2>Clause 9 (User Responsibility and Disclaimer)</h2>
          <ol>
            <li>
              The use of the service is done at the user's own responsibility.
            </li>
            <li>
              The user is responsible for all the information provided to the
              company while using the service.
            </li>
            <li>
              The company does not guarantee that the use of the service will
              result in successful employment or career change.
            </li>
            <li>
              The user shall be responsible for directly confirming the job
              details and employment conditions with the hiring company and
              shall enter employment based on the principle of at-will
              employment. While the company may confirm the employment details
              with the hiring company, it does not guarantee the final
              employment conditions. Company but does not guarantee the final
              employment conditions.
            </li>
            <li>
              If the user suffers damage from using the service, the company
              will only be liable for direct and ordinarily arising damages
              caused by the company's negligence and will not be liable for
              other damages.
            </li>
          </ol>
          <h2>Clause 10 (Compensation for Damages)</h2>
          <p>
            If the user causes damage to the company or its stakeholders or
            third parties while using the service, the user shall compensate for
            such damages and indemnify the company.
          </p>
          <h2>Clause 11 (Changes to the Terms)</h2>
          <p>
            The company may change the contents of these terms without obtaining
            the user's consent when necessary.
          </p>
          <h2>Clause 12 (Matters Not Specified)</h2>
          <p>
            Any matters not specified in these terms shall be resolved through
            sincere consultation between the user and the company.
          </p>
          <h2>Handling of Personal Information</h2>
          <p>
            The company manages and operates the handling of personal
            information as follows:
          </p>
          <h3>Definition of Personal Information</h3>
          <p>
            Personal information refers to information about an individual that
            can identify the person through a combination of data such as name,
            address, employment history, phone number, email address, etc.
          </p>

          <h3>Purpose of Personal Information Collection</h3>
          <p>
            The company collects personal information for the purpose of
            providing appropriate career support services. However, personal
            information may also be used in surveys to improve the company’s
            services.
          </p>
          <h3>Use and Provision of Personal Information</h3>
          <p>
            The personal information collected will be handled within the scope
            of the "Purpose of Personal Information Collection." The company
            will not disclose or provide personal information to companies or
            third parties without the user's consent.
          </p>
          <h3>Provision of Personal Information to Group Companies</h3>
          <p>
            The company may provide personal information to group companies only
            when necessary to carry out the service. The company will comply
            with the Personal Information Protection Law and other related laws
            and regulations when handling personal information. The items of
            personal data provided to group companies include: name, address,
            academic history, work history, etc. The methods of provision to
            group companies include:
          </p>
          <ol className="u_mb30">
            <li>Phone</li>
            <li>Email</li>
            <li>Mail</li>
            <li>Written documents</li>
            <li>
              Oral communication <br /> The provision to group companies can be
              stopped upon the user's request.
            </li>
          </ol>
          <h3>Management of Personal Information</h3>
          <p>
            The company will make reasonable and continuous efforts to implement
            necessary and rational security measures against unauthorized
            access, loss, destruction, alteration, or leakage of personal
            information.
          </p>
          <h3>Administrator</h3>
          <p>
            The company designates the head of the management department as the
            administrator of personal information and works to protect personal
            information appropriately.
          </p>
          <h3>Outsourcing</h3>
          <p>
            When outsourcing system improvement, maintenance, or information
            processing services, the company may outsource the handling of
            personal information. A confidentiality agreement will be signed to
            ensure proper handling of personal information.
          </p>
          <h3>Retention Period for Personal Information</h3>
          <p>
            The company will delete personal information after a period deemed
            appropriate, based on the viewpoint of personal information
            protection. Any documents such as resumes and work histories sent or
            brought to the company will not be returned, regardless of whether
            the company introduces or recommends the user or the outcome of the
            hiring process. The company will take responsibility for properly
            disposing of the information after deletion.
          </p>
          <h3>
            Correction, Disclosure, Deletion (Suspension of Use) of Personal
            Information
          </h3>
          <p>
            If the user requests correction, disclosure, deletion, or suspension
            of use of personal information, the company will respond promptly
            upon confirming that the request is from the user. However, if it
            causes disruption to the company’s operations or if there is a legal
            requirement to retain the information under federal or state law,
            the company may not be able to fulfill such requests.
          </p>
          <h3>Changes and Updates to Terms and Conditions</h3>
          <p>
            These terms and conditions have undergone changes and updates as of
            April 1, 2025. Future changes and updates may occur, and they will
            be valid once published on this website.
          </p>
        </div>
        <Contact lang={"en"} />
      </div>
    </section>
  )
}

export default TermsOfUseEn

export const Head = () => {
  return (
    <Seo
      title="QUICK GLOBAL MEXICO, S.A. Terms of use "
      description="Terms and Conditions of Use of Quick Global México, S.A. de C.V. Services for Client"
      url={`https://www.919mexico.com/en/termsofuse`}
    />
  )
}
